import {allAsNumber, checkNumber} from './utils'

export interface SizeData {
  width: number
  height: number
}

export interface SizeInterface {
  width: () => number
  height: () => number
}

export class Size implements SizeInterface {
  private data: SizeData

  // TODO make validation of income values
  public constructor(data: SizeData) {
    this.data = data
  }

  public width(): number {
    return this.data.width
  }

  public setWidth(value: number) {
    checkNumber(value, true, 'width')
    this.data.width = allAsNumber(value)
  }

  public height(): number {
    return this.data.height
  }

  public setHeight(value: number) {
    checkNumber(value, true, 'height')
    this.data.height = allAsNumber(value)
  }
}
