export declare type LazyLoadState = {
  observer: null | IntersectionObserver
  intersected: boolean
  loaded: boolean
}

let lazyIDX: number = 0;

export const defaultSrcPlaceholder: string = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOceca4HgAFtwIZbTcvNwAAAABJRU5ErkJggg=='

export const clearPicture = (pic: HTMLPictureElement, placeholderSrc?: string): void => {
  Array.from(pic.getElementsByTagName('source')).forEach((sources) => {
    sources.dataset.srcset = sources.srcset
    sources.srcset = ''
  })
  Array.from(pic.getElementsByTagName('img')).forEach(image => {
    image.dataset.src = image.src
    image.src = ''
    image.dataset.srcset = image.srcset
    image.srcset = ''
  })

  if (placeholderSrc && document) {
    let img = document.createElement('img')
    img.dataset.placeholder = 'this is placeholder'
    img.className = 'tes-picture__image_placeholder'
    img.src = placeholderSrc
    pic.appendChild(img);
  }
}

export const restorePicture = (pic: HTMLPictureElement): void => {
  Array.from(pic.getElementsByTagName('source')).forEach((sources) => {
    if (sources.dataset.srcset) {
      sources.srcset = sources.dataset.srcset
    }
    sources.dataset.srcset = ''
  })
  Array.from(pic.getElementsByTagName('img')).forEach(image => {
    if (image.dataset.src) {
      image.src = image.dataset.src
    }
    image.dataset.src = ''

    if (image.dataset.srcset) {
      image.srcset = image.dataset.srcset
    }
    image.dataset.srcset = ''
  })
}

export const observeImages = (pic: HTMLPictureElement): void => {
  Array.from(pic.getElementsByTagName('img')).forEach((image) => {
    if (!image.dataset.placeholder) {
      image.onerror = () => {
        pic.dispatchEvent(new Event('lazy:error'));
      }
      image.onload = () => {
        pic.dispatchEvent(new Event('lazy:load'));
      }
    }
  })
}

export const addEventListenerLazyPicture = (el: HTMLElement, placeholderSrc?: string): void => {
  el.addEventListener('lazy:error', () => {
    el.className = el.className.replace('tes-picture__container_loading', 'tes-picture__container_error')
    if (placeholderSrc) {
      Array.from(el.getElementsByTagName('img')).forEach(image => {
        if (image.dataset.placeholder) {
          image.outerHTML = ''
        } else {
          image.src = placeholderSrc
        }
      })
    }
  })
  el.addEventListener('lazy:load', () => {
    el.className = el.className.replace('tes-picture__container_loading', '')
    if (placeholderSrc) {
      Array.from(el.getElementsByTagName('img')).forEach(image => {
        if (image.dataset.placeholder) {
          image.outerHTML = ''
        }
      })
    }
  })

}

export const prepareLazyPicture = (el: HTMLElement, placeholderSrc?: string): void => {
  lazyIDX++
  clearPicture(el, placeholderSrc)
  addEventListenerLazyPicture(el, placeholderSrc)
  el.dataset.lazyidx = lazyIDX.toString()
}

export const loadLazyElement = (el: HTMLElement): void => {
  //let idx = el.dataset && el.dataset.lazyidx ? parseInt(el.dataset.lazyidx as string) : NaN
  observeImages(el)
  restorePicture(el)
}

export const observeLazyElement = (el: HTMLElement): void => {
  let options = {root: null, threshold: 0}
  let observer = new IntersectionObserver((entities, observer) => {
    entities.forEach((entity) => {
      if (!entity.isIntersecting) {
        return
      }
      observer.unobserve(el) // прекращаем следить за элементом
      loadLazyElement(el) //загружаем его
    })
  }, options)
  observer.observe(el)
}

export const fireLazyElement = (el: HTMLElement): void => {
  if (!el) {
    return
  }
  //let idx = el.dataset && el.dataset.lazyidx ? parseInt(el.dataset.lazyidx as string) : NaN

  if (typeof IntersectionObserver === 'undefined') {
    typeof window !== 'undefined'
      ? window.setTimeout(() => loadLazyElement(el), 100)
      : loadLazyElement(el)
  } else {
    typeof window !== 'undefined'
      ? window.setTimeout(() => observeLazyElement(el), 100)
      : loadLazyElement(el)
  }
}

export const unmountLazyElement = (el: HTMLElement) => {
  if (!el) {
    return
  }
  //let idx = el.dataset && el.dataset.lazyidx ? parseInt(el.dataset.lazyidx as string) : NaN
}

export const prepareImgInHtml = (content: string, attrLoading?: string): string => {
  const imgPattern = /<img(.*?)[\/]?>/igs
  const attrPattern = /([a-zA-Z0-9-_]+)(="([^"]+)")?/igs
  let matchImg: RegExpExecArray | null

  // change data src
  matchImg = imgPattern.exec(content)

  if (matchImg) {
    let matchAttr: RegExpExecArray | null = null
    let hasLoadingAttr: boolean = false

    let oldAttrs: string = matchImg[1].trim()
    let newAttrs: Array<string> = []

    while ((matchAttr = attrPattern.exec(matchImg[1]) as RegExpExecArray | null)) {
      if (matchAttr[1] === 'src' && typeof matchAttr[3] !== 'undefined') {
        newAttrs.push(`src="${defaultSrcPlaceholder}"`)
        newAttrs.push('data-src' + (typeof matchAttr[2] !== 'undefined' ? matchAttr[2] : '=""'))
      } else {
        newAttrs.push(matchAttr[1].trim() + (typeof matchAttr[2] !== 'undefined' ? matchAttr[2] : ''))
        if (matchAttr[1] === 'loading') {
          hasLoadingAttr = true
        }
      }
    }

    if (!hasLoadingAttr && attrLoading && typeof attrLoading !== 'undefined') {
      newAttrs.push(`loading="${attrLoading}"`)
    }

    let imgHtml: string = matchImg[0].replace(oldAttrs, newAttrs.join(' '))

    content = content.replace(matchImg[0], imgHtml)
  }

  return content;
}

export const prepareImgSourceInHtml = (content: string): string => {
  /*
  let match: RegExpExecArray | null = null
  let matchAttr: RegExpExecArray | null = null
  let sourcesPattern = /<source([^>]+)>/igs
  const attrPattern = /([a-zA-Z0-9-_]+)(="([^"]+)")?/igs
  while ((match = sourcesPattern.exec(content) as  RegExpExecArray | null)) {

    let oldAttrs: string = match[1].trim()
    let newAttrs: Array<string> = []

    while ((matchAttr = attrPattern.exec(match[1]) as RegExpExecArray | null)) {
      if (matchAttr[1] === 'src' && typeof matchAttr[3] !== 'undefined') {
        newAttrs.push('data-srcset' + (typeof matchAttr[2] !== 'undefined'? matchAttr[2] : '=""'))
      } else {
        newAttrs.push(matchAttr[1].trim() + (typeof matchAttr[2] !== 'undefined'? matchAttr[2] : ''))
      }
    }

    let newContent: string = match[0].replace(oldAttrs, newAttrs.join(' '))

    content = content.replace(match[0], newContent)
  }*/
  return content.replace(/srcset/ig, 'data-srcset')
}

export const preparePicturesInHtml = (content: string, classSelector: string, classLoading: string): string => {
  const picturePattern = /<picture([^>]+)>(.*?)<\/picture>/igs
  const attrPattern = /([a-zA-Z0-9-_]+)(="([^"]+)")?/igs
  let match: RegExpExecArray | null = null
  let matchAttr: RegExpExecArray | null = null
  let replaceValue: string

  let oldClassValue: string
  let newClassValue: string
  let oldAttrs: string
  let newAttrs: string

  let replaces: Array<RegExpExecArray> = []
  while ((match = picturePattern.exec(content) as RegExpExecArray | null)) {
    if (match[1].indexOf(classSelector) > -1) {
      lazyIDX++

      // match[0].replace(/src/g, `data-src`)
      replaceValue = prepareImgInHtml(prepareImgSourceInHtml(match[0]))

      // prepare figure attrs
      oldClassValue = newClassValue = ''
      oldAttrs = newAttrs = match[1].trim()
      while ((matchAttr = attrPattern.exec(match[1]) as RegExpExecArray | null)) {
        if (matchAttr[1] === 'class' && typeof matchAttr[3] !== 'undefined') {
          oldClassValue = matchAttr[3]
          newClassValue = `${matchAttr[3]} ${classLoading}`
        }
      }

      newAttrs += ` data-lazyidx="${lazyIDX}"`
      newAttrs += ` data-preprocessed="true"`

      replaces.push([match[0], replaceValue.replace(oldAttrs, newAttrs).replace(oldClassValue, newClassValue)] as any)
    }
  }

  for (let k in replaces) {
    content = content.replace(replaces[k][0], replaces[k][1])
  }

  return content
}
/*
export const prepareFigureInHtml = (
  content: string,
  classFigureSelector: string, classFigureLoading: string,
  classPictureSelector: string, classPictureLoading: string
): string => {
  const figPattern = /<figure([^>]+)>(.*?)<\/figure>/igs

  return content
}
*/
