<template>


    <article class="tes-articles-aside__list-item tes-articles-aside__list-item_post" v-for="item in items">

    <span class="tes-articles-aside__item-date">
      <span
          class="tes-articles-aside__item-date-day">{{ item.updatedAt()?.getDate() }}</span> <span
        class="tes-articles-aside__item-date-month"
    >{{ item.updatedAtString('ru') }}</span>
    </span>

      <figure class="tes-articles-aside__item-picture tes-picture__container tes-picture__container_loading">

        <a class="tes-articles-aside__item-picture-link"
           :href="`https://tion.ru/blog/${item.tid()}/`"
           :title="`ссылка на статью:  «${item.header()}»`">
          <LazyLoadPicture
              pictureClass="tes-articles-aside__item-picture-wrapper tes-picture__wrapper"
              imageClass="tes-articles-aside__item-image tes-picture__image"
              :image="item.image()"
              :altEmpty="item.header()"
              imageItemprop="url contentUrl"
              :variants="item.imageVariants()"/>

        </a>
      </figure>

      <div class="tes-articles-aside__item-header">
        <a class="tes-articles-aside__item-header-link"
           :href="`https://tion.ru/blog/${item.tid()}/`"
           :title="`ссылка на статью:  «${item.header()}»`"
        >{{ item.header() }}
        </a>
      </div>

    </article>

    <EllipsisLoader :loading="loading"/>


</template>

<script lang="ts">
import {prepareQuery} from './objects/utils'
import PostListItem, {PostListItemData} from './objects/post/PostListItem'

import {onMounted, ref} from 'vue'
import fetch from 'cross-fetch'

const apiUrlPosts = '/v2/posts/'

interface PaginatorData {
  page: number,
  per_page: number | string
}

export interface PaginationData {
  total: number,
  count: number,
  per_page: number,
  current_page: number,
  total_pages: number
}

function getList(
    path: string,
    props: object | null,
    filter: object | null,
    paginator: PaginatorData | null,
    sort: object | null
): Promise<any> {
  const headers = {
    'x-access-token': `Bearer li4bbLcZffdZ6j1FugrQvMvcodqLfcKE37ewgEhPmyzCxR50GTa07XjiIbDh`,
    'Accept-Language': 'ru',
    'Accept-Tion-Context-Site': 'tion',
    'Accept-Tion-Context-Market': 'russian',
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json'
  }
  return fetch(
      `${import.meta.env.VITE_APP_SERVER_API_URL}${path}?${prepareQuery({...props, ...paginator, sort, filter})}`,
      {
        headers: headers
      }
  )
      .then(
          response => {
            return response.json()
                .then((res: { data: Array<PostListItemData>, meta: PaginationData }) => {
                  if (response.ok) {
                    return res.data;
                  } else {
                    return Promise.reject({status: response.status, res});
                  }
                })
                .catch((error) => console.log(error));
          }
      )
}

export default {
  name: 'TesPostsList',
  components: {},
  setup() {
    const loading = ref(false)
    const items = ref([] as Array<PostListItem>)
    const load = () => {
      loading.value = true
      items.value = []
      getList(
          apiUrlPosts,
          {media: 'aside'}, null, {per_page: 3, page: 1}, ['updated_at,desc']
      )
          .then(res => {
            res.data.forEach((item: PostListItemData) => items.value.push(new PostListItem(item as PostListItemData)))
          })
          .finally(() => loading.value = false)
    }
    onMounted(() => {
      load()
    })
    return {loading, items}
  },
}
</script>