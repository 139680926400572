export function allAsBoolean(value: any): boolean {
  return typeof value === 'boolean' ? value : !!value
}

export function allAsNumber(value: any): number {
  if (typeof value === 'number') {
    return isNaN(value) ? 0 : value
  }

  if (typeof value === 'boolean') {
    return value ? 1 : 0
  }

  if (typeof value !== 'string' || value.length === 0) {
    return 0
  }

  let castValue: number
  value = value.replace(',', '.')
  if (value.includes('.') && !isNaN(castValue = Number.parseFloat(value))) {
    return castValue
  }
  if (!isNaN(castValue = Number.parseInt(value))) {
    return castValue
  }
  return 0
}

export function allAsString(value: any): string {
  return String(value)
}

export function allAsNullOrObject(value: any): object | null {
  return typeof value === 'object' && !Array.isArray(value) ? value : null
}

export function allAsNullOrString(value: any): string | null {
  return typeof value === 'string' ? value : null
}

export function allAsUndefinedOrString(value: any): string | undefined {
  return typeof value === 'string' ? value : undefined
}

export function checkNumber(value: any, nullable: boolean, field: string): void {
  if (typeof value !== 'number' && (!nullable || (nullable && value !== null && value !== undefined))) {
    errorHelper(field, value, 'number', nullable)
  }
}

export function checkString(value: any, nullable: boolean, field: string): void {
  if (typeof value !== 'string' && (!nullable || (nullable && value !== null && value !== undefined))) {
    errorHelper(field, value, 'string', nullable)
  }
}

export function checkNull(data: any, field: string): void {
  if (data !== null) {
    errorHelper(field, data, 'null', false)
  }
}

export function checkUndefined(data: any, field: string): void {
  if (data !== undefined) {
    errorHelper(field, data, 'undefined', false)
  }
}

export function checkNullable(data: any, field: string): void {
  if (data !== null && data !== undefined) {
    errorHelper(field, data, 'null or undefined', false)
  }
}

/*
export function throwNull2NonNull (field: string, data: any): never {
  return errorHelper(field, data, 'non-nullable object', false)
}

export function throwNotObject (field: string, data: any, nullable: boolean): never {
  return errorHelper(field, data, 'object', nullable)
}
export function throwIsArray (field: string, data: any, nullable: boolean): never {
  return errorHelper(field, data, 'object', nullable)
}
*/

export function errorHelper(field: string, data: any, type: string, nullable: boolean): never {
  if (nullable) {
    type += ', null or undefined'
  }
  throw new TypeError('Expected ' + type + ' at ' + field + ' but found:\n' + JSON.stringify(data))
}

export function saveToStore(name: string, value: any) {
  return (typeof localStorage !== 'undefined') ? localStorage.setItem(name, JSON.stringify(value)) : null;
}

export function loadFromStore(name: string): any {
  let val: string | null = (typeof localStorage !== 'undefined') ? localStorage.getItem(name) : null
  return !val ? val : JSON.parse(val)
}

export function prepareQuery(params: any, node: string | null = null): string {
  let tmp: Array<string> = []
  for (let key in params) {
    let param = params[key]
    if (typeof param === 'object') {
      tmp.push(prepareQuery(param, node ? (`${node}[${key}]`) : key))
    } else if (typeof param !== 'undefined') {
      tmp.push((node ? `${node}[${key}]` : key) + `=${param}`)
    }
  }
  return tmp.join('&');
}

export const dateMonthShort = (date: any, locale: string = 'ru'): string => {
  if (typeof date !== 'string' || (typeof date === 'object' && !(date as Object instanceof Date))) {
    return ''
  }
  date = new Date(date)
  return date
    ? date.toLocaleString(locale, {month: 'short'}).replace('.', '')
    : ''
}
export const dateMonthDay = (date: any): string => {
  date = new Date(date)
  return date
    ? date.getDate().toString()
    : ''
}
export const dateIso = (date: any): string => {
  date = new Date(date)
  return date
    ? date.toISOString()
    : ''
}
export const isPromise = (obj: any): boolean => {
  return !!obj && (typeof obj === "object" || typeof obj === "function") && typeof obj.then === "function"
}

export const merge = (target: any, source: any) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
  }
  Object.assign(target || {}, source)
  return target
}

export const generateKeyForObject = (data: object, prefix: string | null | undefined = undefined): string => {
  return (prefix ? (prefix + '_') : '') + JSON.stringify(data)
    .replace(/\{/g, '')
    .replace(/\}/g, '')
    .replace(/\:/g, '-')
    .replace(/,/g, '_')
    .replace(/\[/g, '')
    .replace(/\]/g, '')
    .replace(/"/g, '')
    .replace(/'/g, '')
}
