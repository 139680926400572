<!--
<template>

  <picture ref="el" @load="load">

    <source :media="variant.width() ? `(max-width: ${variant.width()}px)` : ''"
            :type="variant.image().mimeType()"
            :srcset="variant.image().src()"
            v-for="variant in sources">

    <img :src="src"
         :data-src="image?.src()"
         :width="`${width}`"
         :height="`${height}`"
         :alt="alt"
         :class="imageClass"
         @load="load"
         @error="error"
         :itemprop="imageItemprop"/>
  </picture>

  <span class="tes-article-read-also__item-picture-overlay tes-picture__overlay" v-if="!state.loaded">
      <PuffSpinner class="tes-article-read-also__item-picture-spinner tes-picture__spinner"/>
  </span>

</template>
-->

<script lang="ts">
import {MediaInterface} from '../../objects/Media'
import {MediaVariant} from '../../objects/MediaVariant'
import {LazyLoadState, defaultSrcPlaceholder} from '../../objects/LazyImage'

import {computed, ComputedRef, h, onBeforeUnmount, onMounted, PropType, reactive, ref, SetupContext} from 'vue'
import {VNode} from '@vue/runtime-core'

//import PuffSpinnerUrl from '~/assets/loaders/puff.svg?url'
import PuffSpinner from '~/assets/loaders/puff.svg'


interface LazyLoadPictureProps {
  pictureClass: string,
  image: null | MediaInterface
  imageClass?: string | boolean
  imageItemprop: string
  srcPlaceholder: string
  altEmpty: string
  widthPlaceholder: string
  heightPlaceholder: string
  variants: Array<MediaVariant>
}

export default {
  name: 'LazyLoadPicture',
  props: {
    pictureClass: {
      type: String,
      default: ''
    },
    image: {
      type: [Object as PropType<MediaInterface>, null],
      required: true,
      default: null
    },
    imageClass: {
      type: String,
      default: ''
    },
    imageItemprop: {
      type: [String, Boolean, undefined],
      default: false
    },
    srcPlaceholder: {
      type: String,
      default: defaultSrcPlaceholder
    },
    altEmpty: {
      type: String,
      default: ''
    },
    widthPlaceholder: {
      type: String,
      default: '100%'
    },
    heightPlaceholder: {
      type: String,
      default: 'auto'
    },
    variants: {
      type: Array as PropType<Array<MediaVariant>>,
      required: true,
      default: []
    }
  },
  emits: ['load', 'error', 'intersect'],
  setup: function (props: LazyLoadPictureProps, {emit}: SetupContext) {

    const el = ref<HTMLElement | null>(null)

    let state = reactive({
      observer: null,
      intersected: false,
      loaded: false,
    } as LazyLoadState)

    const alt: string = (typeof (props.image?.alt()) === 'string')
        ? ('' + props.image.alt())
        : props.altEmpty

    const src: ComputedRef<string> = computed<string>((): string => {
      if (state.intersected && props.image) {
        return props.image.src()
      }
      return props.srcPlaceholder;
    })

    const width: ComputedRef<string> = computed<string>((): string => {
      if (state.intersected && props.image) {
        return props.image.size().width().toString()
      }
      return props.widthPlaceholder
    })

    const height: ComputedRef<string> = computed<string>((): string => {
      if (state.intersected && props.image) {
        return props.image.size().height().toString()
      }
      return props.heightPlaceholder
    })

    const sources: ComputedRef<Array<MediaVariant>> = computed((): Array<MediaVariant> => {
      return (state.intersected && props.variants) ? props.variants : []
    })

    const load = (): void => {
      if (src.value !== props.srcPlaceholder) {
        state.loaded = true;
        emit('load', el.value);
      }
    }

    const error = (): void => emit('error', el.value)

    const loadImage = () => {
      if (window) {
        if (el.value && typeof IntersectionObserver !== 'undefined') {
          let options = {root: null, threshold: 0}
          state.observer = new IntersectionObserver((entries) => {
            const image = entries[0]
            if (image.isIntersecting) {
              state.observer?.disconnect()
              state.observer = null
              state.intersected = true
              emit('intersect')
            }
          }, options)
          state.observer.observe(el.value)
        } else {
          state.observer = null
          state.intersected = true
        }
        window.removeEventListener('scroll', loadImage)
        window.removeEventListener('touchstart', loadImage)
        document.removeEventListener('mouseenter', loadImage)
        document.removeEventListener('click', loadImage)
        window.removeEventListener('DOMContentLoaded', loadImage)
      }
    }

    onMounted(() => {
      if (window && !state.intersected) {
        window.addEventListener('scroll', loadImage)
        window.addEventListener('touchstart', loadImage)
        document.addEventListener('mouseenter', loadImage)
        document.addEventListener('click', loadImage)
        window.addEventListener('DOMContentLoaded', loadImage)
      }
    })

    onBeforeUnmount(() => {
      if (state.observer) {
        state.observer.disconnect()
        state.observer = null
      }
      if (window) {
        window.removeEventListener('scroll', loadImage)
        window.removeEventListener('touchstart', loadImage)
        document.removeEventListener('mouseenter', loadImage)
        document.removeEventListener('click', loadImage)
        window.removeEventListener('DOMContentLoaded', loadImage)
      }
    })

    return () => {

      let objects: Array<VNode> = []
      sources.value.forEach(variant => {
        objects.push(
            h('source', {
              media: variant.width() ? `(max-width: ${variant.width()}px)` : '',
              type: variant.image().mimeType(),
              srcset: variant.image().src(),
            })
        )
      })

      objects.push(
          h(
              'img',
              {
                src: src.value,
                'data-src': props.image?.src(),
                width: `${width.value}`,
                height: `${height.value}`,
                alt: alt,
                'class': props.imageClass,
                onLoad: load,
                onError: error,
                'itemprop': props.imageItemprop
              }
          )
      )
      let items: Array<VNode> = [h('picture', {ref: el, onLoad: load, 'class': props.pictureClass}, objects)]
      if (!state.loaded) {
        items.push(
            h(
                'span',
                {'class': 'tes-picture__overlay'},
                [
                  h(
                      //h('img', {'src': PuffSpinnerUrl}),
                      PuffSpinner,
                      {'class': 'tes-picture__spinner'}
                  )
                ]
            )
        );
      }
      return items
    }
  }

}
</script>
