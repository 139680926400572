import {PostListItemData} from "./PostListItem";

export interface PostTagData {
  id: number | null
  tid: string
  title: string
  description: string | null
  post_type_tid: string | null
  count: number
  sort_num: number,
  items?: Array<PostListItemData>
}

export interface PostTagInterface {
  id(): number | null

  tid(): string

  path(): string

  title(): string

  sortNum(): number

  count(): number

  isMyId(id: number | string | null): boolean
}

export default class PostTag implements PostTagInterface {
  private data: PostTagData

  public constructor(data: PostTagData) {
    this.data = data
  }

  public id(): number | null {
    return this.data.id
  }

  public tid(): string {
    return this.data.tid
  }

  public path(): string {
    let t = 'tag'
    switch (this.data.post_type_tid) {
      case 'news':
        t = 'novosti'
        break;
    }
    return `/${t}/${this.data.tid}/`
  }

  public title(): string {
    return this.data.title
  }

  public sortNum(): number {
    return this.data.sort_num
  }

  public count(): number {
    return this.data.count
  }

  isMyId(id: number | string | null): boolean {
    return !!(id && ((Number.isInteger(id) && this.id() === id) || this.tid() === id))
  }
}
