import {Size, SizeData} from './Size'
import {allAsUndefinedOrString} from './utils'

export interface MediaData {
  title?: string
  dimensions: SizeData
  url: string
  type: MediaType
  mime_type: string
}

export enum MediaType {image = 'image', video = 'video', frame = 'frame'}

export interface MediaInterface {
  alt(): string | undefined

  size(): Size

  src(): string

  type(): MediaType

  mimeType(): string
}

export class Media implements MediaInterface {
  private data: MediaData
  private readonly _size: Size

  // TODO make validation of income values
  public constructor(data: MediaData) {
    this.data = data
    this._size = new Size(data.dimensions ? data.dimensions as SizeData : {} as SizeData)
  }

  public alt(): string | undefined {
    return this.data.title
  }

  public size(): Size {
    return this._size
  }

  public src(): string {
    return this.data.url
  }

  public type(): MediaType {
    return this.data.type
  }

  public setAlt(value?: string | null): void {
    this.data.title = allAsUndefinedOrString(value)
  }

  public mimeType(): string {
    return this.data.mime_type
  }
}
