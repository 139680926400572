import {Media, MediaData} from './Media'

export interface MediaVariantData {
  width: number | null
  image: MediaData
}

export interface MediaVariantInterface {
  width(): number | null

  image(): Media
}

export class MediaVariant implements MediaVariantInterface {
  private data: MediaVariantData
  private readonly _image: Media

  public constructor(data: MediaVariantData) {
    this.data = data
    this._image = new Media(data.image)
  }

  public width(): number | null {
    return this.data.width
  }

  public image(): Media {
    return this._image
  }
}
