import PostTag, {PostTagData, PostTagInterface} from './PostTag'
import {Media, MediaData, MediaInterface} from '../Media'
import {MediaVariant, MediaVariantData} from "../MediaVariant";

export interface PostListItemData {
  id: number | null
  tid: string
  adult: boolean
  age_rating: number
  allow_comment: boolean
  comment_count: number
  dislike_count: number
  like_count: number
  sort_num: number
  tags: Array<PostTagData>
  type_tid: string
  view_count: number
  voice_count: number
  voice_sum: number
  voice_average: number
  header: string
  sub_header: string | null
  description: string
  image: MediaData | null
  image_variants: Array<MediaVariantData>
  published_at: string | null
  updated_at: string | null
}

export interface PostListItemInterface {
  id(): number | null

  tid(): string

  adult(): boolean

  ageRating(): number

  allowComment(): boolean

  countComments(): number

  countDislikes(): number

  countLikes(): number

  sortNum(): number

  hasTags(): boolean

  tags(): Array<PostTagInterface>

  type(): string

  countViews(): number

  countVoices(): number

  sumVoices(): number

  averageVoices(): number

  averageVoicesToStyle(): number

  header(): string

  subHeader(): string | null

  description(): string

  image(): MediaInterface | null

  hasImageVariants(): boolean

  imageVariants(): Array<MediaVariant>

  publishedAt(): Date | null

  updatedAt(): Date | null

  publishedAtString(locale: string): string

  updatedAtString(locale: string): string
}

export default class PostListItem implements PostListItemInterface {

  private data: PostListItemData
  private readonly _tags: Array<PostTagInterface>
  private readonly _image: Media | null
  private readonly _image_variants: Array<MediaVariant>
  private readonly _publishedAt: Date | null
  private readonly _updatedAt: Date | null

  public constructor(data: PostListItemData) {
    this.data = data

    this._tags = []
    if (Array.isArray(data.tags)) {
      for (let tag of data.tags) {
        this._tags.push(new PostTag(tag as PostTagData))
      }
    }
    this._image = data.image ? new Media(data.image as MediaData) : null
    this._publishedAt = data.published_at ? new Date(data.published_at) : null
    this._updatedAt = data.updated_at ? new Date(data.updated_at) : null
    this._image_variants = []
    if (Array.isArray(data.image_variants)) {
      for (let imgVariant of data.image_variants) {
        this._image_variants.push(new MediaVariant(imgVariant))
      }
    }
  }

  public id(): number | null {
    return this.data.id;
  }

  public tid(): string {
    return this.data.tid;
  }

  public header(): string {
    return this.data.header
  }

  public subHeader(): string | null {
    return this.data.sub_header
  }

  public description(): string {
    return this.data.description
  }

  public hasTags(): boolean {
    return this._tags.length > 0
  }

  public tags(): Array<PostTagInterface> {
    return this._tags
  }

  public type(): string {
    return this.data.type_tid
  }

  public image(): MediaInterface | null {
    return this._image
  }

  public hasImageVariants(): boolean {
    return this._image_variants.length > 0
  }

  public imageVariants(): Array<MediaVariant> {
    return this._image_variants
  }

  public allowComment(): boolean {
    return this.data.allow_comment
  }

  public adult(): boolean {
    return this.data.adult
  }

  public ageRating(): number {
    return this.data.age_rating
  }

  public countDislikes(): number {
    return this.data.dislike_count
  }

  public countLikes(): number {
    return this.data.like_count
  }

  public countComments(): number {
    return this.data.comment_count
  }

  public countViews(): number {
    return this.data.view_count
  }

  public countVoices(): number {
    return this.data.voice_count
  }

  public sumVoices(): number {
    return this.data.voice_sum
  }

  public averageVoices(): number {
    return this.data.voice_average
  }

  public averageVoicesToStyle(): number {
    return Math.ceil(this.data.voice_average) * 100
  }

  public sortNum(): number {
    return this.data.sort_num
  }

  public publishedAt(): Date | null {
    return this._publishedAt
  }

  public updatedAt(): Date | null {
    return this._updatedAt
  }

  public publishedAtString(locale: string = 'ru'): string {
    return this._publishedAt
      ? this._publishedAt.toLocaleString(locale, {month: 'short'}).replace('.', '')
      : ''
  }

  public updatedAtString(locale: string = 'ru'): string {
    return this._updatedAt
      ? this._updatedAt.toLocaleString(locale, {month: 'short'}).replace('.', '')
      : ''
  }
}
