import { createApp } from 'vue'
import TesPostsList from '@/TesPostsList.vue'
import LazyLoadPicture from '@/components/lazy/LazyLoadPicture.vue'
import EllipsisLoader from '@/components/loader/EllipsisLoader.vue'

import '@/styles/tes-posts-list.scss'

createApp(TesPostsList)
    .component('LazyLoadPicture', LazyLoadPicture)
    .component('EllipsisLoader', EllipsisLoader)
    .mount('#tes-posts-list')
